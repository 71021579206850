<template>
  <div class="identity">
    <BaseHeaderBar
      title="Informasi Identitas"
      :showCustomerService="false"
      :showBackButton="true"
    />
    <div class="ktpUploade">
      <div class="title">KTP</div>
      <div class="uploade_box">
        <img class="shili" src="@/assets/img/KTP_shili.png" alt="" />
        <div class="uploade">
          <input
            type="file"
            accept="image/*"
            capture=""
            ref="videoFile"
            @change="afterKtpRead"
            class="file_input"
          />
          <img v-if="ktpImg" class="uploadeImg" :src="ktpImg" alt="" />
          <img
            v-else
            class="uploadeImg"
            src="@/assets/img/identity.png"
            alt=""
          />

          <img class="camera" src="@/assets/img/identity-camera.png" alt="" />
        </div>
      </div>
    </div>
    <div class="renlianUploade">
      <div class="title">Fitur Pengenalan Wajah</div>
      <div class="uploade_box">
        <img class="shili" src="@/assets/img/renlia_shili.png" alt="" />
        <div class="uploade" @click="afterUserRead">
          <img v-if="userImg" class="uploadeImg" :src="userImg" alt="" />
          <img
            v-else
            class="uploadeImg"
            src="@/assets/img/recognition.png"
            alt=""
          />

          <img class="camera" src="@/assets/img/identity-camera.png" alt="" />
        </div>
      </div>
    </div>
    <button type="button" class="confirm-btn" @click="onSubmit">Kirim</button>
    <van-overlay :show="onSubmitShow">
      <van-loading color="#2952E5" vertical></van-loading>
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
import { Uploader, Toast, Loading, Overlay, Image as VanImage } from 'vant'
import axios from 'axios'
import Compressor from 'compressorjs'
import request from '@/utils/request'
import EXIF from 'exif-js'

Vue.use(Uploader)
Vue.use(Toast)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(VanImage)

function randomString (len = 32) {
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

export default {
  name: 'Identity',
  data () {
    return {
      imgSrc: '',
      ktpImg: '',
      ktpLoadingInfo: {
        show: false,
        text: 'Proses Unggah'
      },
      userImg: '',
      userLoadingInfo: {
        show: false,
        text: 'Proses Unggah'
      },
      onSubmitShow: false,
      KtpImgSource: null,
      userImgSource: null
    }
  },
  created () {
    this.getIdentify()
    this.uploadPromise = this.getUploadParams()
  },

  methods: {
    getIdentify () {
      request.post('iddrentityInfoPage')
        .then(res => {
          this.userImg = res.usdrerLiving_imgurl
        })
    },
    takePhoto () {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const video = document.getElementById('video')
      const mediaConstraints = {
        audio: false,
        video: {
          width: { ideal: 375 },
          height: { ideal: 250 },
          frameRate: {
            ideal: 30,
            max: 10
          },
          facingMode: 'user'
        }
      }
      const errBack = function (error) {
        console.log('Video capture error: ', error.code)
      }
      setInterval(() => {
        context.drawImage(video, 0, 0, 375, 250)
        this.imgSrc = canvas.toDataURL('image/png')
      }, 3000)

      const getUserMedia = this.isSupportUserMedia()
      if (getUserMedia) {
        getUserMedia(mediaConstraints, function (stream) {
          video.srcObject = stream
          video.play()
        }, errBack)
      }
    },
    isSupportUserMedia () {
      return navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia
    },
    getUploadParams () {
      return request.post('updrloadImgToken')
    },
    uploadImg (file) {
      return new Promise((resolve, reject) => {
        this.uploadPromise.then(res => {
          const key = 'web/' + Date.now() + randomString(10) + '-' + file.name
          const formData = new FormData()
          formData.append('OSSAccessKeyId', 'LTAI5tD9wrLfvn8sEpRLfpbw')
          formData.append('key', key)
          formData.append('policy', res.podrlicy)
          formData.append('signature', res.sidrgnature)
          formData.append('file', file)
          formData.append('success_action_status', 200)
          axios.post('https://inphotos.oss-ap-southeast-1.aliyuncs.com', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => resolve(`https://inphotos.oss-ap-southeast-1.aliyuncs.com/${key}`))
            .catch(error => {
              Toast('Unggal Gagal')
              reject(error)
            })
        }).catch(error => {
          Toast('Unggal Gagal')
          reject(error)
        })
      })
    },
    afterKtpRead () {
      console.log(111111)

      // 获取到input标签的上传的文件对象
      const files = this.$refs.videoFile.files[0]
      // 通过FileReader构造函数创建一个新的FileReader对象
      const reader = new FileReader()
      // 通过新创建的FileReader对象获取input元素上传的files文件对象，并使用readAsDataURL()方法读取
      reader.readAsDataURL(files)
      // 这是一个异步操作，当读取完成后，result属性中将包含一个data: URL格式的Base64字符串以表示所读取文件的内容
      reader.onload = () => {
        this.compressImage(files, result => {
          this.uploadImg(result).then(async res => {
            this.ktpImg = res
            Toast('Berhasil submit')
            const isFromCamera = await this.isImageFromCamera(files)
            this.KtpImgSource = isFromCamera ? 1 : 2
          })
        })
      }
    },
    compressImage (file, success) {
      let quality = 1
      const size = file.size
      const baseQuality = 1024 * 1024
      if (size < baseQuality) { // 小于1M
        quality = 0.8
      } else if (size < 5 * baseQuality) { // 小于5M
        quality = 0.4
      } else if (size < 10 * baseQuality) { // 小于10M
        quality = 0.1
      } else { // 大于10M
        quality = 0
      }
      console.log('size', size, quality)
      new Compressor(file, {
        quality,
        success
      })
    },
    deleteKtp () {
      this.ktpImg = ''
    },
    deleteUser () {
      this.userImg = ''
    },
    afterUserRead () {
      if (!this.ktpImg) {
        Toast('Silakan foto KTP')
        return false
      }
      if (this.userImg) {
        return
      }
      this.$router.push({
        path: '/userRead'
      })
    },
    isImageFromCamera (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          const img = new Image()
          img.onload = () => {
            EXIF.getData(img, () => {
              const orientation = EXIF.getTag(img, 'Orientation')
              resolve(orientation !== undefined) // EXIF的Orientation标签存在，则可能是相机拍摄
            })
          }
          img.src = event.target.result
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    validate () {
      if (!this.ktpImg) {
        Toast('Silakan foto KTP')
        return false
      }
      if (!this.userImg) {
        Toast('Silakan foto selfie')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      this.onSubmitShow = true
      const postData = {
        phdrotoList: JSON.stringify([{
          phdrotoUrl: this.ktpImg,
          phdrotoType: '1',
          uploadType: navigator.platform,
          imgSource: this.KtpImgSource,
          uuid: sessionStorage.getItem('ixabisoelinye')
        }, {
          phdrotoUrl: this.userImg,
          phdrotoType: '2',
          uploadType: navigator.platform,
          imgSource: this.userImgSource,
          uuid: sessionStorage.getItem('ixabisoelinye')
        }])
      }
      request.post('iddrentityInfosubmit', postData)
        .then(() => {
          Toast('Pembaruan infomasi identitas berhasil')
          this.$router.back()
        })
        .catch(res => {
          if (res && res.code === 502) {
            this.deleteKtp()
          }
        })
        .finally(() => {
          this.flag = false
          this.onSubmitShow = false
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    const { userReadUrl, isFromCamera } = to.query
    if (userReadUrl) {
      next(vm => {
        // 在这里，`vm` 是组件的实例，你可 以访问它的数据和方法
        vm.userImg = userReadUrl
        vm.userImgSource = isFromCamera
      })
      return
    }
    next()
  }
}
</script>

<style scoped lang="less">
.ktpUploade,
.renlianUploade {
  height: 354px;
  border-radius: 8px;
  background: #fff;
  margin: 30px;
  padding: 0 44px;
  .title {
    font-weight: 400;
    font-size: 28px;
    color: #333333;
    text-align: center;
    padding-top: 23px;
    margin-bottom: 45px;
  }
  .uploade_box {
    display: flex;
    justify-content: space-between;
    .shili {
      width: 283px;
      height: 178px;
    }
    .uploade {
      position: relative;
      background: #fffae9;
      border-radius: 16px;
      width: 283px;
      height: 178px;
      .file_input {
        position: relative;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
      }
      .uploadeImg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .camera {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 57px;
      }
    }
  }
}
.renlianUploade {
  .uploade_box {
    display: flex;
    justify-content: center;
    gap: 40px;
    .shili {
      width: 219px;
      height: 219px;
    }
    .uploade {
      position: relative;
      background: #fffae9;
      border-radius: 16px;
      width: 219px;
      height: 219px;
    }
  }
}
.identity {
  background: #f8f8f8;
  min-height: 100vh;
}
.content {
  margin-top: 40px;
  & > div {
    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-top: -36px;
      margin-left: 40px;
    }

    p {
      width: 306px;
      line-height: 30px;
      font-size: 28px;
      font-weight: 500;
      color: #fff;

      text-align: center;
    }

    .identity-camera {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      z-index: 999;
      pointer-events: none;
    }

    /deep/ .van-loading {
      position: absolute;
    }
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.6);

  img {
    width: 40px;
    height: 40px;
  }
}

.confirm-btn {
  .submit-btn;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150px;
  width: 704px;
}
</style>
